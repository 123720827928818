import React from "react";
import "../styles/main.css";
import TrackVisibility from "react-on-screen";

import ContactForm from "./contact-form";
import ContactButtons from "./contact-buttons";
import Projects from "./projects";

import profilePic from "../img/pixel_profile.png";

class Main extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="header">
          <img className="profile-pic" src={profilePic} alt="profile" />
          <div className="header-text">
            <div style={{ fontSize: "40px", fontWeight: "800", marginBottom: "20px" }}>
              Gabriel Dezan
            </div>
            <div style={{ marginBottom: "10px" }}>
              Computer Engineering Student at the University of São Paulo
            </div>
            <div>Web Development Intern at Stoq Tecnologia</div>
          </div>
        </div>
        <div className="main">
          <Projects />
          <ContactForm />
          <div style={{ margin: "30px 0 20px 0" }}>You can also find me in these places below:</div>
          <TrackVisibility>
            <ContactButtons />
          </TrackVisibility>
        </div>
      </div>
    );
  }
}

export default Main;
