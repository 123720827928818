import React from "react";
import "../styles/main.css";

class CButton extends React.Component {
  state = {
    anim: "hidden",
  };

  render() {
    this.props.visible &&
      setTimeout(() => {
        this.setState({ anim: "scale-up-center" });
      }, (parseInt(this.props.order) - 1) * 80);
    return (
      <a
        href={this.props.href}
        className={this.state.anim}
        target="blank"
        style={{ display: "inline-block", margin: "10px", textDecoration: "none" }}
      >
        <div className={`contact-button noselect`}>
          <i className={this.props.icon} />
          <div style={{ marginLeft: "10px" }}>{this.props.name}</div>
        </div>
      </a>
    );
  }
}

export default class ContactButtons extends React.Component {
  render() {
    const { isVisible } = this.props;
    return (
      <div style={{marginBottom: 50}}>
        <CButton
          name="E-Mail"
          order="1"
          icon="fas fa-envelope"
          href="mailto:dezangabriel@gmail.com"
          visible={isVisible}
        />
        <CButton
          name="Github"
          order="2"
          icon="fab fa-github"
          href="https://github.com/gdezan"
          visible={isVisible}
        />
        <CButton
          name="LinkedIn"
          order="3"
          icon="fab fa-linkedin"
          href="https://www.linkedin.com/in/gdezan"
          visible={isVisible}
        />
        <CButton
          name="freeCodeCamp"
          order="4"
          icon="fab fa-free-code-camp"
          href="https://www.freecodecamp.org/gdezan"
          visible={isVisible}
        />
      </div>
    );
  }
};