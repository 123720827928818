import React from "react";
import "../styles/main.css";
import swal from "sweetalert";
import redArrow from "../img/red_arrow.png";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default class ContactForm extends React.Component {
  state = {
    open: "",
    name: "",
    email: "",
    message: "",
  };

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => {
        this.setState({
          name: "",
          email: "",
          message: "",
        });
        swal({
          title: "Success!",
          text: "Message sent!",
          icon: "success",
        });
      })
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, message, open } = this.state;
    let sOpen = open !== "form-open" ? "form-open" : "form-close";
    return (
      <div style={{ marginTop: 90 }}>
        <h1>Contact</h1>
        <div>
          <p>Feel free to send me a message by clicking on the envelope!</p>
          <img
            src={redArrow}
            style={{ visibility: "hidden" }}
            alt="red arrow"
            className="arrow-img"
          />
          <div
            className="contact-button"
            onClick={() => this.setState({ open: sOpen })}
            style={{
              display: "inline-block",
              padding: "10px",
              fontSize: "28px",
              marginBottom: "20px",
            }}
          >
            <i className="fas fa-envelope " />
          </div>
          <img src={redArrow} alt="red arrow" className="arrow-img" />
        </div>
        <div className={`form-frame ${open}`}>
          <div className="form-content">
            <form onSubmit={this.handleSubmit}>
              <p>
                <label>
                  <input
                    type="text"
                    name="name"
                    className="input-styling"
                    value={name}
                    placeholder="Your Name"
                    onChange={this.handleChange}
                  />
                </label>
              </p>
              <p>
                <label>
                  <input
                    type="email"
                    name="email"
                    className="input-styling"
                    value={email}
                    placeholder="Your E-Mail"
                    onChange={this.handleChange}
                  />
                </label>
              </p>
              <p>
                <label>
                  <textarea
                    name="message"
                    className="input-styling"
                    style={{ height: "150px" }}
                    value={message}
                    placeholder="Your Message"
                    onChange={this.handleChange}
                  />
                </label>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", width: "80%", justifyContent: "flex-end" }}>
                  <button type="submit" className="submit-button">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
