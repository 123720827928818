import React from "react";
import "../styles/main.css";

import Project from "./project";

export default class Projects extends React.Component {
  state = {
    page: 1,
    leftDisabled: true,
    rightDisabled: false,
    projects: [
      {
        name: "Drum Machine",
        description: `A eletronic drum machine made in React. You can play sounds from a drum kit
                     (with two sound banks) with your keyboard`,
        github: "https://github.com/gdezan/drum_machine",
        href: "https://gdezan.github.io/drum_machine/",
        img: require("../img/drum_machine.jpg"),
      },
      {
        name: "Simon®",
        description: `A recreation in vanilla JavaScript of the Simon® game`,
        github: "https://github.com/gdezan/simon",
        href: "https://gdezan.github.io/simon/",
        img: require("../img/simon.jpg"),
      },
      {
        name: "Pomodoro Clock",
        description: `A simple clock using the "Pomodoro Technique". Made with vanilla JavaScript`,
        github: "https://github.com/gdezan/pomodoro",
        href: "https://pomodoroclock.netlify.com/",
        img: require("../img/pomodoro.jpg"),
      },
      {
        name: "Tic-Tac-Toe",
        description: `Implementation of the classic game Tic-Tac-Toe. Single Player mode uses an AI
                      with the minimax algorithm`,
        github: "https://github.com/gdezan/tictactoe",
        href: "https://tictac.netlify.com/",
        img: require("../img/tic_tac_toe.jpg"),
      },
      {
        name: "JavaScript Calculator",
        description: `Simple calculator, made in vanilla JavaScript`,
        github: "https://github.com/gdezan/tictactoe",
        href: "https://jscalc.netlify.com/",
        img: require("../img/calc.jpg"),
      },
      {
        name: "Wikipedia Viewer",
        description: `Webpage to search for Wikipedia articles, displaying a list of results using
                      Wikipedia's API`,
        github: "https://github.com/gdezan/wikipedia_viewer",
        href: "https://gdezan.github.io/wikipedia_viewer/",
        img: require("../img/wikipedia.jpg"),
      },
    ],
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, projects, mobile } = this.state;
    const pageMax = mobile ? projects.length : Math.ceil(projects.length / 2);

    if (prevState.page !== page) {
      switch (page) {
        case 1:
          this.setState({ leftDisabled: true, rightDisabled: false });
          break;
        case pageMax:
          this.setState({ leftDisabled: false, rightDisabled: true });
          break;
        default:
          this.setState({ leftDisabled: false, rightDisabled: false });
          break;
      }
    }
  }

  resize() {
    this.setState({ mobile: window.innerWidth <= 760 });
  }

  nextPage = () => {
    const { page, projects, mobile } = this.state;
    const pageMax = mobile ? projects.length : Math.ceil(projects.length / 2);
    if (page >= pageMax) return;
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    const { page } = this.state;
    if (page === 1) return;
    this.setState({ page: page - 1 });
  };

  jumpToPage = pageNumber => {
    this.setState({ page: pageNumber });
  };

  createIndicators = () => {
    const { mobile, projects } = this.state;
    let indicators = [];
    const pageMax = mobile ? projects.length : Math.ceil(projects.length / 2);
    for (let i = 0; i < pageMax; i++) {
      indicators.push(
        <div key={i} className="page-circle" onClick={() => this.jumpToPage(i + 1)} />,
      );
    }
    return indicators;
  };

  renderIndicators() {
    const indicatorsStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transform: "translateX(-15px)",
    };

    return (
      <div style={indicatorsStyle}>
        <div
          className="page-selected"
          style={{ transform: `translateX(${29 * (this.state.page - 1)}px)` }}
        />
        {this.createIndicators()}
      </div>
    );
  }

  render() {
    const { page, projects, mobile, leftDisabled, rightDisabled } = this.state;
    const flexCenter = { display: "flex", alignItems: "center", justifyContent: "center" };
    const translateAmount = mobile ? 80 * (page - 1) : 60 * (page - 1);
    return (
      <div style={{ marginTop: 80 }}>
        <h1>Portfolio</h1>
        <p>Here are some of the projects I made!</p>
        <div style={flexCenter}>
          {!mobile && (
            <i
              className={`fas fa-angle-left slide-button ${leftDisabled ? "hidden" : ""}`}
              onClick={() => this.previousPage()}
            />
          )}
          <div>
            <div className="projects-box">
              <div
                className="project-line"
                style={{ transform: `translateX(-${translateAmount}vw)` }}
              >
                {projects.map((project, i) => {
                  if (i % 2 !== 0 && !mobile) return null;
                  return <Project {...project} key={i} />;
                })}
              </div>
              <div
                className="project-line delay"
                style={{ transform: `translateX(-${translateAmount}vw)` }}
              >
                {!mobile &&
                  projects.map((project, i) => {
                    if (i % 2 === 0) return null;
                    return <Project {...project} imagePosition="right" key={i} />;
                  })}
              </div>
            </div>
          </div>
          {!mobile && (
            <i
              className={`fas fa-angle-right slide-button ${rightDisabled ? "hidden" : ""}`}
              onClick={() => this.nextPage()}
            />
          )}
        </div>
        <div style={{ ...flexCenter, marginBottom: 50 }}>
          {mobile && (
            <i
              className={`fas fa-angle-left slide-button ${leftDisabled ? "hidden" : ""}`}
              onClick={() => this.previousPage()}
            />
          )}
          {this.renderIndicators()}
          {mobile && (
            <i
              className={`fas fa-angle-right slide-button ${rightDisabled ? "hidden" : ""}`}
              onClick={() => this.nextPage()}
            />
          )}
        </div>
      </div>
    );
  }
}
