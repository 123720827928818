import React from "react";
import "../styles/main.css";

export default class Projects extends React.Component {
  static defaultProps = {
    imagePosition: "left",
  };
  render() {
    const { imagePosition } = this.props;
    let rowReverse = "";
    let imageStyle = {};
    let githubStyle = {};
    if (imagePosition === "right") {
      rowReverse = "reverse";
      imageStyle = { marginLeft: 50 };
      githubStyle = { left: -15 };
    } else {
      rowReverse = "";
      imageStyle = { marginRight: 50, flexDirection: "row" };
      githubStyle = { left: 15 };
    }
    return (
      <div
        className={`project-card ${rowReverse}`}
        style={{
          textAlign: imagePosition,
        }}
      >
        <div className="img-gh" style={imageStyle}>
          <a href={this.props.href} target="blank">
            <div>
              <img className="project-image" src={this.props.img} alt={this.props.name} />
            </div>
          </a>
          <div className="github-view" style={githubStyle}>
            <a href={this.props.github} target="blank">
              <i className="fab fa-github" style={{ marginRight: 10 }} /> View on Github
            </a>
          </div>
        </div>
        <a href={this.props.href} target="blank">
          <div>
            <div className="project-title">{this.props.name}</div>
            <div className="project-description">{this.props.description}</div>
          </div>
        </a>
      </div>
    );
  }
}
